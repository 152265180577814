#white-tooltip .MuiTooltip-tooltip {
  @extend .bg-white, .border, .default-radius, .box-shadow;
  max-height: 360px;
  overflow: auto;
}

.popover-package {
  width: 500px;
  @extend .px-2, .pb-2;
}
