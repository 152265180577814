.MuiChip-root{
  background-color: $light-gray !important;
}
.MuiChip-root.MuiChip-deletable {
  background-color: $light-gray !important;
}
.MuiChip-root.MuiChip-outlined{
  background-color: transparent !important;
}
.MuiChip-root.MuiChip-outlined.MuiChip-deletable{
  background-color: transparent !important;
}
