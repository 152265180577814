.country-flagData-width {
  width: 5.6rem;
}

.platform-icon-size{
  width: 30px;
  height: 30px;
}

.country-flag {
  width: 26px;
  height: 26px;

  &.rounded {
    img {
      width: 100%;
      height: 100%;
      border-radius: 1rem;
      object-fit: cover;
      cursor: pointer;
    }
  }
}

.bg-light-gray{
  background-color: $background-color;
}
