.border-top-bottom {
  border-top: 1px solid $light-gray;
  border-bottom: 1px solid $light-gray;
}

.border-soft-danger {
  border: 1px solid #fedee3;
}

.border-soft-primary {
  border: 1px solid #c3e9ec;
}

.box-shadow-primary {
  box-shadow: 1px 1px 14px -3px #006f72db;
}

.border.border-2 {
  border-width: 2px !important;
}

.border-radius-8px {
  border-radius: 8px;
}

.default-border {
  border: 1px solid $secondary;
}

.default-dashed-border {
  border: 1px dashed $light-gray;
}

.default-border-lighter {
  border: 1px solid $gray-300;
}

.border-left {
  border-left: 1px solid $light-gray
}

.border-danger {
  border: 1px solid $danger;
}

.border-top-right {
  border-top: 1px solid $light-gray;
  border-right: 1px solid $light-gray;
  flex-grow: 1;
}

.border-bottom {
  border-bottom: 1px solid $light-gray;
}

.border-bottom-dark {
  border-bottom: 1px solid $black;
}

.border-bottom-primary {
  border-bottom: 1px solid $primary;
}

.border-dashed {
  border: 2px dashed $light-gray;
}

.border-right {
  border-right: 2px solid $light-gray;
}

.border-warning-right {
  border-top-right-radius: $default-radius;
  border-bottom-right-radius: $default-radius;
  border-right: 4px solid $warning;
}
