.sent-offers {
  border-right: 2px solid $light-gray;
}


@media(max-width: 520px) {

  .influencer-dashboard-img {
    margin: 0.5rem 0;
  }

  .sent-offers {
    border: 1px solid $primary-light;
    border-radius: 8px;
    box-shadow: 1px 1px 14px 4px #eeeeee;
  }
  .received-offers {
    border: 1px solid $info-lighter;
    border-radius: 8px;
    box-shadow: 1px 1px 14px 4px #eeeeee;
  }

}



