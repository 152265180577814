.Toastify__toast-theme--light {
  border: 0px;
  border-left: 5px solid;
  border-radius: 10px !important;
  font-size: 14px;
  min-height: 52px;

  &.Toastify__toast--success {
    border-left-color: $primary;

    .Toastify__toast-icon {
      svg {
        fill: $primary;
      }
    }
  }

  &.Toastify__toast--warning {
    border-left-color: $warning;

    .Toastify__toast-icon {
      svg {
        fill: $warning;
      }
    }
  }

  &.Toastify__toast--error {
    border-left-color: $danger;

    .Toastify__toast-icon {
      svg {
        fill: $danger;
      }
    }
  }

  .Toastify__progress-bar-theme--light {
    background-color: $gray-400 !important;
    height: 3px;
  }
}
