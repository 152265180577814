.notification-dropdown {
  &.bell-list {
    position: absolute;
    right: 0;
    width: 300px;
    max-height: 400px;
    overflow: auto;

    &.mobile {
      right: -50%;
    }
  }

  .item {
    padding: 5px;
    cursor: pointer;

    .notification-icon {
      width: 40px;
      height: 40px;
    }

    &.unseen {
      @extend .bg-muted;
    }

    &:hover {
      background-color: $gray-100;
    }
  }
}
