@media (min-width: 768px) {
  .right-side-dashboard {
    padding-left: 2rem;
    border-left: 1px solid $border-color;
  }
  .left-side-dashboard {
    padding-right: 2rem;
  }
}

@media (max-width: 768px) {
  .left-side-dashboard {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.campaign-list-card {
  .table {
    border-collapse: separate;
    border-spacing: 0 1rem;
  }

  thead {
    th {
      padding-bottom: 0;
      border: 0;
    }
  }

  tbody {
    tr {
      background-color: white;
      border: 1px solid $light-gray !important;
      border-radius: 7px;
    }

    td {
      padding: 0 !important;
      vertical-align: middle;
      text-align: center;
      border-bottom: 1px solid $light-gray !important;
    }

    td:last-child {
      border-right: 1px solid $light-gray;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    td:first-child {
      border-bottom-left-radius: $border-radius;
      border-top-left-radius: $border-radius;
    }
  }

  img {
    object-fit: cover;
    height: 65px;
    width: 100px;
    border-bottom-left-radius: $default-border-radius;
    border-top-left-radius: $default-border-radius;
  }
}

.offer-bullet {
  position: absolute;
  top: 9px;
  right: 4px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: $danger;

  &.dashboard-dot {
    top: 10px;
    right: 12px;
  }
}

.notification-bullet {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $danger;
  border: 5px solid $white;
  position: absolute;
  top: -2px;
  right: 6px;

  &.calendar {
    width: 10px;
    height: 10px;
    border: 1px solid $white;
  }

  &.offer {
    top: -5px;
    right: -5px;
  }
}

.notification-bullet-sm {
  @extend .notification-bullet;
  width: 15px;
  height: 15px;
  border: 3px solid $white;
}

.campaign-card-img {
  @extend .box-shadow-angle;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  object-fit: cover;
}

.campaign-card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.campaign-card-title:hover {
  color: $primary;
}

.campaign-card-status {
  position: absolute;
  top: 20px;
  right: 20px;
  width: fit-content;
}

.filter-text {
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }

  &.active {
    color: $primary;
  }
}

.autocomplete-search {
  position: absolute;
  top: 34px;
  right: 25px;
  background-color: white;
  color: $light-text;
}
