.login {
  min-height: 100vh;
}

.auth-bg-image {
  //background-image: url('../../../images/img-login.jpg');
  background-size: cover;
  background-position: right;
}
.registration-card:hover{
  border: 1px solid $primary;
}

.registration-img{
  height: 65px;
  object-fit: cover;
  border-radius: 0.4rem 0 0 0.4rem;
  position: relative;
  width: 6rem;
}
.auth-logo {
  margin-top: 10px;
}

.auth-language-switch {
  position: absolute;
  bottom: 10px;
  right: 10px;

  @media (max-width: 768px) {
    bottom: 15px;
    left: 15px;
  }
  .dropdown-menu {
    min-width: 3rem;
  }
  .flag {
    width: 30px;
    height: 20px;
    object-fit: cover;
    margin-right: .25rem;
    border-radius: $default-border-radius;
  }
}

.login-heading {
  font-weight: $font-weight-normal;
}

.blur-background {
  backdrop-filter: blur(5px);
}

.auth-bg-text {
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.6); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  text-align: left;
  padding: 2rem;
  border-radius: $default-border-radius;

  .hr {
    height: 1px;
    width: 20%;
    background-color: white;
    border-radius: 20%;
    opacity: 50%;
  }
}

.influencer-register-page {
  @media (max-width: 768px) {
    h3 {
      font-size: 18px;
    }
  }
}
