.campaign-information {
  .campaign-image {
    width: 100px;
    height: 100px;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  box-shadow: 0 4px 5px -2px $box-shadow-color;

  p {
    color: $light-text;
  }

  p:first-child {
    color: $dark-text;
    margin-bottom: 0;
    font-weight: $font-weight-regular;
    font-size: 10px;
  }
}

.tabs-container {
  height: 4.5rem;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $default-border-radius;
  cursor: pointer;
  text-align: center;
  width: 2.875rem;
  height: 2.875rem;
  border: 1px solid $light;
  filter: grayscale(100%);

  &.active {
    filter: grayscale(0);

    .heart {
      svg {
        background-color: $success-lighten;
      }
    }

    .edit {
      svg {
        background-color: $warning-lighten;
      }
    }

    .exit {
      svg {
        background-color: $danger-lighten;
      }
    }
  }

  .heart {
    .active {
      filter: grayscale(0);
    }
  }

  &:hover {
    filter: grayscale(0);

    .heart {
      svg {
        background-color: $success-lighten;
      }
    }

    .edit {
      svg {
        background-color: $warning-lighten;
      }
    }

    .exit {
      svg {
        background-color: $danger-lighten;
      }
    }
  }

  svg {
    font-size: 1.85rem;
    border-radius: $default-border-radius;
    padding: 0.4rem;
    z-index: 2;
  }
}


.nav-tabs .nav-link.active {
  color: $primary;
  background-color: $background-color;
  border-color: $background-color;
  box-shadow: inset 0 5px 5px -5px $box-shadow-color,
  inset -5px 0 5px -5px $box-shadow-color,
  inset 5px 0 5px -5px $box-shadow-color;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  font-weight: $font-weight-bold;
}

.nav-link {
  padding: 0.6rem 0.8rem;
  box-shadow: 0 4px 5px -2px $box-shadow-color;
  text-align: center;
  width: 25%;
  margin-bottom: 0px !important;
}

.nav-tabs {
  border-bottom: 0;
  background-color: $white;
  padding-top: 0.6em;
  font-weight: $font-weight-medium;
  font-size: 16px;

  a {
    background-color: $white;
    color: $dark-text;
    text-transform: uppercase;
  }
}

.nav-tabs .nav-link:hover {
  border-color: white !important;
  border-bottom-color: $background-color !important;
}

.tabs-mobile {
  background-color: $background-color;
  border-top: 1px solid $light-gray;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 80px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;

  .tab {
    text-align: center;
  }

  p {
    margin-bottom: 0px;
    font-size: 12px;
  }
}

.transparent:hover {
  background-color: transparent !important;
}

.closeIcon {
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
}

.title {
  width: 345px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.history-profile-img {
  border-top-left-radius: $default-border-radius;
  border-top-right-radius: $default-border-radius;
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.change-campaign {
  @extend .position-absolute, .w-75, .shadow;
  z-index: 2;
  bottom: -100px;
  left: -8px;
}