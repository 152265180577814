@mixin font-size() {
  $font-size-list: 9 10 11 12 13 14 16 18 20 22 24 25 28 40 45 48 50 100 120;

  @each $value in $font-size-list {
    .font-#{$value} {
      font-size: $value + px;
    }
  }
}

.display-5 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.text-end {
  text-align: end;
}

@include font-size();

@mixin truncate-lines($max-lines...) {
  @each $lines in $max-lines {
    .truncate-#{$lines} {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: #{$lines};
      overflow: hidden;              
    }
  }
}

@include truncate-lines(1, 2, 3, 4, 5);

.font-weight-semi-bold {
  font-weight: $font-weight-semi-bold;
}

.font-weight-bold {
  font-weight: $font-weight-bold;
}

.font-weight-extra-bold {
  font-weight: $font-extra-bold;
}

.light-text {
  color: $light-text;
}

.text-primary {
  color: $primary;
}

.text-info {
  color: $info;
}

.text-warning {
  color: $warning;
}

.text-danger {
  color: $danger;
}

.text-danger-hover {
  color: $light-text;
  &:hover {
    color: $danger;
  }
}

.text-line-through {
  text-decoration: line-through;
  text-decoration-thickness: 2px;
}

.lh-normal {
  line-height: normal;
}

.text-success {
  color: $success;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-weight-semi-bold;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

span {
  font-size: $font-size-base;
}

p {
  font-size: $font-size-base;
}

.break-text {
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 75%;
}

.text-muted {
  color: $light-text !important;
}

.text-underline {
  text-decoration: underline !important;
}

.white-space {
  white-space: nowrap;
  width: max-content;
  overflow: hidden;
  text-overflow: ellipsis;
}
