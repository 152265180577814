.breadcrumb-bullet .breadcrumb-item+.breadcrumb-item::before {
  content: '•';
}

.breadcrumb-item a{
  color: $light-text;
}
.breadcrumb-item.active {
  color: $dark-text ;
  a{
    color: $dark-text ;
  }
}
.breadcrumb {
  background-color: $white;
  font-size: 14px;
  font-weight: $font-weight-regular;
  margin-bottom:0px;
  padding: 0.50rem 1rem;
}