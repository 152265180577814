//Colors
$primary: #05bbc9;
$primary-light: #C3E9EC;
$primary-darker: #2F86B8; //darken($primary, 30%);
$primary-lighter: lighten($primary, 56%);
$secondary: #97a5a8;
$secondary-lighter: lighten($secondary, 50%);
$info: #952cb7;
$info-lighter: #F5E1FC;
$success: #68cb92;
$success-lighten: lighten($success, 25%);
$success-lighter: #ecfff4;
$warning: #fc9d79;
$warning-lighten: lighten($warning, 20%);
$warning-lighter: #fff7f3;
$danger: #ed4052;
$danger-lighten: lighten($danger, 30%);
$danger-lighter: #fff8f9;
$black: #3b3b3b;
$light-gray: #eeeeee;
$white: #ffffff;
$yellow: #f6b925;
$proposed: $secondary;
$feedback_loop: #FFD2A8;
$feedback_loop-lighten: #FFD2A833;
$proposed-lighten: #EBF8FF;

$background-color: #fafafa;

//Text color
$dark-text: #3b3b3b;
$light-text: #9ea5ab;

//Font size
$font-size-sm: 0.625rem;
$font-size-base: 0.75rem;
$font-size-lg: 0.875rem;

//Font weight
$font-weight-regular: 300;
$font-weight-medium: 400;
$font-weight-semi-bold: 500;
$font-weight-bold: 600;
$font-extra-bold: 700;

//Gray colors
$gray-100: #f3f6f9 !default;
$gray-200: #ebedf3 !default;
$gray-300: #e4e6ef !default;
$gray-400: #d1d3e0 !default;
$gray-500: #b5b5c3 !default;
$gray-600: #7e8299 !default;
$gray-700: #5e6278 !default;
$gray-800: #3f4254 !default;
$gray-900: #181c32 !default;

// Dark colors
$dark: $gray-900 !default;
$dark-75: $gray-800 !default;
$dark-65: $gray-700 !default;
$dark-50: $gray-600 !default;
$dark-25: $gray-400 !default;

$box-shadow-color: #00000010;
$border-gray-light: #eeeeee;
$default-border-radius: 7px;
$default-border-color: #d3d7db;
// Hover background color
$hover-bg: $gray-100;

// Activebackground color
$active-bg: darken($gray-100, 1%) !default; // Custom variable

$default-transition: 0.25s;

$transition: all 0.15s ease !default; // Custom variable

// Page Paddings
$page-padding: (
        desktop: 80px,
  // Padding for desktop mode
        tablet-and-mobile: 15px // Padding for tablet and mobile modes,,
) !default;

// Table
$table-bg: transparent !default;
$table-accent-bg: $gray-200 !default;
$table-hover-bg: $gray-300 !default;
$table-active-bg: $table-hover-bg !default;
$table-font-size: 12px;

$table-border-color: $gray-200 !default;
$table-head-bg: $gray-100 !default;
$table-head-color: $gray-600 !default;
$table-head-font-size: 12px !default; // Custom variable
$table-head-font-weight: $font-weight-semi-bold !default; // Custom variable

$table-dark-color: $white !default;
$table-dark-bg: $dark !default;
$table-dark-accent-bg: rgba($white, 0.05) !default;
$table-dark-hover-color: $table-dark-color !default;
$table-dark-hover-bg: rgba($white, 0.075) !default;
$table-dark-border-color: lighten($table-dark-bg, 7.5%) !default;

$table-compact-head-font-size: 0.9rem !default; // Custom variable
$table-compact-head-color: $gray-500 !default; // Custom variable
$table-compact-head-text-transform: uppercase !default; // Custom variable
$table-compact-head-font-weight: 600 !default; // Custom variable
$table-compact-head-letter-spacing: 0.1rem !default; // Custom variable

// Border-radius
$default-radius: 7px;

:export {
  primary: $primary;
  secondary: $secondary;
  danger: $danger;
  warning: $warning;
  info: $info;
  black: $black;
  white: $white;
}
