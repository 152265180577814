.alert {
  border-left: 5px solid !important;
  border: 0px;
  border-radius: 10px ;
  background: white;
  font-size: 14px;
}
.alert-primary{
  border-left-color: $primary !important;
}

.alert-danger{
  border-left-color: $danger !important;
}

.alert-warning{
  border-left-color: $warning !important;
}