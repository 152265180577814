.activity {
  display: flex;
  align-items: center;
  cursor: pointer;

  .icons-bg {
    width: 50px;
    height: 50px;
  }

  .title {
    font-weight: bold;
    font-size: 14px;
  }

  .text {
    font-size: 12px;
    color: $gray-500;
  }
}

.icons-bg {
  &.close-btn {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 3px;
    right: 5px;

    .icon {
      width: 15px;
      height: 15px;
    }
  }
}