@media (min-width: 768px) {
  .modal-xl {
    max-width: 650px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1350px;
  }
}

.selected-user {

  position: relative;

  .selected-agency-photo {
    border: 2px solid $primary;
  }

  .active-badge {
    position: absolute;
    padding: 0 10px;
    border-radius: 15px;
    background-color: $primary;
    color: $white;
  }
}

.support-modal {
  .modal-content {
    background-image: url('https://i.pinimg.com/736x/8c/98/99/8c98994518b575bfd8c949e91d20548b.jpg');
    background-position: center;

    .message-bubble {
      width: fit-content;
      color: #111B21;
      padding: .5rem;
      border-radius: $default-border-radius;
      background: $white;
      @extend .box-shadow;
      //box-shadow: 0 1px 0.5px rgba(11, 20, 26, .13);
    }
  }
}

.close {
  margin-top: -10px !important; // close icon on every modal
  & span {
    font-size: 1.5rem;
  }
}

@media (min-width: 992px) {
  .calendar-update-modal .modal-dialog {
    max-width: 1000px;
  }
}

#management_modal {
  .modal-content {
    max-height: 95vh;
  }
}

.preview-modal-scroll {
  max-height: 50vh;
  overflow: auto;
}