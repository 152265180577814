.reporting-card {
  .title {
    position: absolute;
    top: 1rem;
    left: 1.3rem;
  }
}

.campaign-status-dashboard {
  bottom: 10px;
  z-index: 1;
  right: 10px;
  position: absolute;
}

.img-campaign-list {
  @extend .img-cover;
  -o-object-fit: cover;
  height: 50px;
  max-width: 85px;
  min-width: 85px;
  border-top-left-radius: $default-border-radius;
  border-bottom-left-radius: $default-border-radius;
}
