.user-img {
  width: 32px;
  height: 32px;
  object-fit: cover;

  img {
    width: 100%;
    height: 100%;
    border-radius: 7px;
  }
}

.user-header-logo {
  //border: 2px solid red;
}

.thumbnail-image {
  filter: blur(1px);
  animation: breathing 2s infinite alternate; /* Add the breathing animation */
}

@keyframes breathing {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}

.tutorial-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  cursor: pointer;
  border-radius: $default-border-radius;
}

.top-right-icon {
  position: absolute;
  cursor: pointer;
  top: 5%;
  right: 5%;
}

.campaign-feedback {
  border-radius: $default-border-radius;
  cursor: pointer;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.tutorial-placeholder {
  width: 300px;
  height: 150px;
  object-fit: cover;
  margin-right: .5rem;
  cursor: pointer;
  border-radius: $default-border-radius;
}

.tutorial-dot {
  cursor: pointer;
  display: inline-block;
  border-radius: 50%;
  margin: 0 .25rem;
  width: 10px;
  height: 10px;
  background: $primary;

  &:not(.active) {
    opacity: 0.25;
  }
}

.ribbon-bg {
  width: 3rem;
}

.rounded-img-sm {
  @extend .rounded-circle, .box-shadow-angle;
  width: 4rem;
  aspect-ratio: 1/1;
  object-fit: cover;

  &.comment-img {
    width: 3rem;
  }
}

.w-250px {
  width: 250px;
}

.user-img-rounded {
  @extend .rounded-circle;
  left: 0.5rem;
  top: 0.2rem;
  width: 2rem;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.best-pick-border {
  background: transparent;
  background-image: linear-gradient(90deg, white, white),
  linear-gradient(-30deg, #F6B927, white);
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: 3px solid transparent;
}

.user-img-rounded-lg {
  @extend .rounded-circle, .box-shadow-angle;
  width: 3.75rem;
  height: 3.75rem;
  object-fit: cover;
  @media(max-width: 520px) {
    width: 2.75rem;
    height: 2.75rem;
  }
}

.user-placeholder {
  @extend .rounded-circle;
  width: 2.5rem;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.icon-sm {
  width: 1.5rem;
}

.profile-image {
  @extend .rounded-circle, .box-shadow-angle;
  width: 7rem;
  aspect-ratio: 1/1;
  object-fit: cover;
  position: relative;
}

.feed-image {
  width: 100%;
  aspect-ratio: 1/1.2;
  object-fit: cover;
  border-radius: $default-radius;
  margin: 1rem 0;
}

.review-image {
  @extend .box-shadow-angle;
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: $default-radius;
  margin: 1rem 0;
}

.moonboard-images {
  @extend .default-radius, .w-100;
  height: 200px;
  object-fit: cover;
}

.dashboard-sm-image {
  @extend .box-shadow-angle;
  aspect-ratio: 1/1.5;
  width: 4.5rem;
  object-fit: cover;
  border-radius: $default-radius;
}

.small-rounded-logo {
  box-shadow: 0px 3px 6px #00000029;
  width: 35px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
}

.middle-rounded-logo {
  @extend .small-rounded-logo;
  width: 40px;
}

.calendar-filter-logo {
  @extend .small-rounded-logo, .ml-3, .cursor-pointer;

  &.active {
    border: 3px solid $primary;
  }
}


.selected-input-logo {
  object-fit: cover;
  position: absolute;
  top: 15%;
  margin-left: 5px;
  width: 25px;
  aspect-ratio: 1/1;
  z-index: 1;
  border-radius: 50%;
}

.selected-long-term {
  object-fit: cover;
  position: absolute;
  top: 25%;
  right: 15%;
  z-index: 1;
}

.icon-upload > input {
  display: none;
}

.explore-images {
  :first-child {
    padding-left: 0;
  }

  :last-child {
    padding-right: 0;
  }
}

.influencer-dashboard-img {
  width: 100%;
  height: 170px;
  object-fit: cover;
  border-radius: $default-radius;
  margin: 1rem 0;
}

.influencer-dashboard-img-sm {
  object-fit: cover;
  width: 90%;
  border-radius: $default-radius;
}

.influencer-mobile-image {
  width: 90px;
  height: 85px;
  border-radius: 7px;
}


.highlighted-post {
  height: 200px;
  @extend .w-100, .default-radius, .img-cover, .box-shadow;
}

.highlighted-post-cancel {
  @extend .d-flex, .align-items-center, .justify-content-center, .box-shadow, .border;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: white;
  position: absolute;
  z-index: 1;
  top: -10px;
  right: -5px;
  cursor: pointer;

}
