.custom-datepicker .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 0.4375em;
}

.custom-datepicker .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -8px;
  color: rgba(0, 0, 0, 0.54);
}

.custom-datepicker .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

.custom-datepicker
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: $primary;
}

.custom-datepicker {
  .rdrDateDisplay {
    .rdrDateDisplayItem + .rdrDateDisplayItem {
      display: none;
    }
  }

  .rdrDayEndPreview, .rdrDay {
    border: none;
  }

  .datepicker-label {
    background: $white;
    padding: 0 10px;
  }

  .datepicker-field {
    border-radius: $default-border-radius;
    border: 1px solid rgba(0, 0, 0, 0.23);
    outline: none;
    padding: 0 12px;
  }
}

#muiDatePicker label.Mui-focused {
  color: $primary;
}

.PrivateDatePickerToolbar-penIcon {
  display: none !important;
}

#muiDatePicker .MuiInputAdornment-positionEnd button {
  width: 100%;
}

#muiDatePicker .MuiTextField-root {
  width: 100%;
}

.MuiButtonBase-root-MuiPickersDay-root.Mui-selected, .MuiPickersDay-root.Mui-selected {
  background-color: $primary !important;
  color: $white !important;
}

#muiDatePicker .Mui-focused fieldset {
  border-color: $primary !important;
}

#muiDatePicker label.Mui-focused {
  color: $primary;
}

#muiDatePicker fieldset {
  border-radius: $default-radius;
}

#muiDatePicker .MuiOutlinedInput-root {
  @extend .py-0;
}

#muiDatePicker .MuiInputLabel-root {
  font-size: 12px;
}