@import "base/variables";
@import "base/palette";
@import "~bootstrap/scss/bootstrap";
@import "base/body";
@import "base/typography";
@import "base/general";
@import "layout/init";
@import "components/init";
@import "components/images";
@import "components/datepicker";
@import "components/notification";
@import "components/dropzone";
@import "pages/init";

body > iframe[style*='2147483647'] {
  display: none;
}
