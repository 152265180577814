*::-webkit-scrollbar {
  width: 4px;
  border-radius: $default-border-radius;
  height: 4px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: $default-border-radius;
}

*::-webkit-scrollbar-thumb {
  border-radius: $default-border-radius;
  background-color: $primary;
}
