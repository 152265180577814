.MuiInputBase-input {
  font-size: 12px !important;
}

.MuiOutlinedInput-root {
  padding-right: 0 !important;
  min-height: 34px;
}

.MuiInputLabel-outlined.MuiInputLabel-marginDense {
  font-size: 12px;
}

.MuiOutlinedInput-adornedStart {
  padding-left: 8px !important;
}

.MuiRating-root {
  label {
    margin-bottom: 0 !important;
  }
}

.MuiSvgIcon-root {
  width: 1.5rem !important;
  height: 1.5rem !important;

}

.MuiInputLabel-root {
  &.Mui-focused {
    color: $primary !important;
  }
}

.MuiSlider-rail {
  background-color: $light-text !important;
  height: 4px !important;
}

.MuiSlider-track {
  height: 4px !important;
}


.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-today {
  background-color: $primary !important;
  color: $white !important;
}

.MuiMenuItem-root {
  font-size: $font-size-base !important;
}

.MuiTabs-indicator {
  background-color: $primary !important;
}


@media only screen and (max-width: 768px) {
  .MuiButtonBase-root.MuiTab-root {
    font-size: 12px;
  }
  .MuiInputBase-root {
    font-size: .8rem !important;
  }
}

//.MuiIconButton-edgeEnd {
//  @extend .w-100;
//}
