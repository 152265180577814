.dashboard-slider {

  img {
    width: 100%;
    aspect-ratio: 1/1.1;
    object-fit: cover;
    border-radius: $default-border-radius;
  }

  .carousel-item:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    border-radius: $default-border-radius;
    height: 40%;
  }
}
