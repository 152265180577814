.rdw-editor-toolbar {
  @extend .border, .mb-0, .pb-1;
}

.rdw-editor-main {
  @extend .border-bottom, .border-left, .border-right, .px-2;
}

.rdw-dropdown-wrapper.rdw-list-dropdown {
  z-index: 2;
}

.text-editor-height {
  .ql-editor {
    height: 180px !important;
  }
}
