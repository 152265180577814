@media (min-width: 1200px) {
  .modal-xl#create-campaign-modal {
    max-width: 1000px;
  }
}

.middle-col-margin {
  &:nth-child(3n-2) {
    @extend .pl-md-0;
  }

  &:nth-child(3n-1) {
    padding-left: calc(15px / 2);
    padding-right: calc(15px / 2);
  }

  &:nth-child(3n-3) {
    @extend .pr-md-0;
  }

  @media only screen and (max-width: 768px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.create-campaign-card {
  cursor: pointer;

  img {
    border-top-left-radius: $default-radius;
    border-top-right-radius: $default-radius;
    height: 160px;
    object-fit: cover;
    width: 100%;
  }
}

.campaign-overview-card {
  width: 100%;

  img:first-child {
    height: 40%;
    width: 100%;
    object-fit: cover;
    border-radius: $default-radius;
  }
}

#edit-icon:hover {
  color: $primary;

}

#edit-icon {
  color: $secondary;
  width: 23px;
  height: 23px;
}

.removeCompetitors {
  position: absolute;
  top: -8px;
  right: 15px;
  width: 10px;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
  }
}

.DraftEditor-root {
  height: auto !important;
}

.ql-editor {
  height: 350px !important;
  overflow: auto;
}

.barter-deal-width {
  width: 100px;
}