.MuiButtonBase-root {
  border-top: 1px solid #dedede;

  .MuiAccordionSummary-content {
    display: block;
  }
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root {
  box-shadow: none;
}

.MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters {
  border-top: 1px solid $gray-300;
}

.MuiPaper-root.MuiPaper-elevation:before {
  height: 0;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  padding-right: 1rem;
}

#did-you-know_accordion {
  background-color: $background-color;
}

#planner-accordion {
  .MuiAccordionSummary-content {
    @extend .my-0;
  }
}