.agency-header {
  height: 4.5rem;
  background-color: $white;
  box-shadow: 0 4px 5px -2px $box-shadow-color;
  padding: 0 20px;

  .active-user {
    position: absolute;
    padding: 0px 10px;
    border-radius: 15px;
    background-color: $primary;
    color: $white;
    bottom: -10px;
    left: -3px;
  }

  .user-border {
    border: 2px solid $primary;
  }

  .user-red-dot {
    position: absolute;
    top: 0px;
    right: 4px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: $danger;
  }
}

.agency-filter-rounded-img {
  @extend .rounded-circle, .shadow, .cursor-pointer;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 20px;
    height: 20px;
  }
}

.red-dot {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: $danger;

}

.agency-offer-profile-img {
  @extend .rounded-circle, .box-shadow-angle;
  @media (max-width: 1200px) {
    width: 45px;
    height: 45px;
  }
  object-fit: cover;
  width: 85px;
  height: 85px;
}

#agencyMessageTabs {
  .MuiTabs-flexContainer.MuiTabs-centered {
    width: 100%;
  }
}