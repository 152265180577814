.infobox {
  background-color: transparent;
  border-radius: $default-border-radius;
  border: 1px solid;
  padding: 1rem;
  margin-bottom: 1.5rem;

  .btn {
    text-transform: none;
  }
}

.infobox {
  &.danger {
    border-color: $danger;
  }

  &.success {
    border-color: $success;
  }

  &.warning {
    border-color: $warning;
  }

  &.info {
    border-color: $info;
  }

  &.primary {
    border-color: $primary;
  }
}
