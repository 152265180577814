.side-chat {
  max-height: 500px;
  overflow: auto;

  &.opened {
    @extend .p-3;
    opacity: 1;
    transition: width 0.25s ease, opacity 0.33s cubic-bezier(1, 0.18, 0, 1.53);
    width: 100%;
  }

  &.closed {
    visibility: hidden;
    overflow: hidden;
    width: 0;
    opacity: 0;
    transition: width 0.25s ease, opacity 0.2s cubic-bezier(0, 1.68, 1, 0.66);
  }
}

.scrollable-chat-height {
  height: 50vh;
}

.big-rounded-logo {
  width: 100px;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 50%;
}

.responsive-chat-body-height {
  overflow: auto;
  max-height: 60vh;
  min-height: 60vh;
}

@media only screen and (max-width: 768px) {
  .col-md-3 {
    .responsive-chat-body-height {
      min-height: 55vh;
      max-height: 55vh;
    }
  }

  .col-md-9 {
    .responsive-chat-body-height {
      min-height: 50vh;
      max-height: 50vh;
      overflow: hidden;
    }
  }

  .scrollable-chat-height {
    height: 45vh;
  }
}

.global-chat-bubble {
  @extend .position-fixed, .flex-row-reverse, .align-items-end;
  display: flex;
  min-width: 20%;
  max-width: 80%;
  bottom: 0;
  z-index: 2;
  right: 0;
  left: 0;
  margin-left: auto;

  .row {
    flex-direction: inherit;
    align-items: end;
  }

  .chat-accordion {
    z-index: 16 !important;
    @extend .default-radius, .border, .mb-0;
    //width: 100%;
    width: 300px;
  }


  .MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters {
    @extend .border-top-0;
  }

  .user-chat {
    @extend .pl-1, .mt-0;
  }

  .scrollable-div {
    max-height: 65vh;
    overflow: auto;

    &.selected-bubble {
      height: 400px;
    }

    .chat-sent-message {
      max-width: 90%;
    }
  }
}

.infinite-scroll-component {
  overflow: unset !important;
}

aside.emoji-picker-react {
  @extend .position-absolute;
  width: auto !important;
  bottom: 100%;
  left: -300px;
  z-index: 2;
}

@media only screen and (max-width: 991px) {
  .global-chat-bubble {
    &.mobile {
      bottom: 80px;
    }

    .chat-accordion {
      width: fit-content;
    }
  }
  aside.emoji-picker-react {
    left: 0;
  }
}


@media only screen and (min-width: 1300px) {
  .responsive-chat-body-height {
    max-height: 56vh;
    min-height: 56vh;
  }

}

@media only screen and (max-width: 768px) and (min-width: 1299px) {
  .responsive-chat-body-height {
    max-height: 60vh;
    min-height: 60vh;
  }
}