.image-container {
  position: relative;
  width: 100%;
  margin: auto;
}

.responsive-image {
  width: 100%;
  height: auto;
  display: block;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 2em; /* Adjust size as needed */
  height: 2em; /* Adjust size as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s ease;
}

.play-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.play-button::before {
  content: '';
  display: block;
  width: 0; 
  height: 0; 
  border-left: 0.5em solid white;
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
}