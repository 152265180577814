.Mui-selected {
  .icon-round {
    background: $primary !important;
  }

  color: $black !important;
  font-weight: bold;
}

.MuiBox-root .MuiTabPanel-root {
  padding: 1rem 0;

  span {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
}

#campaign-tabpanel {
  div[role="tabpanel"] .MuiBox-root {
    @extend .px-0
  }
}

.influencer-tab-filter {
  cursor: pointer;
  min-height: 37px;
  border: 1px solid $default-border-color;

  &.primary:hover {
    border-color: $primary;
  }

  &.danger:hover {
    border-color: $danger;
  }

  &.primary-dark:hover {
    border-color: $primary-darker;
  }

  &.warning:hover {
    border-color: $warning;
  }

  &.primary, &.primary-dark, &.warning, &.danger {
    color: $text-muted;
  }

  &.active.primary {
    svg {
      fill: $white;
    }

    color: $white;
    background-color: $primary !important;
  }

  &.active.primary-dark {
    svg {
      fill: $white;
    }
    color: $white;
    background-color: $primary-darker !important;
  }

  &.active.danger{
    svg {
      fill: $white;
    }
    color: $white;
    background-color: $danger !important;
  }

  &.active.warning {
    svg {
      fill: $white;
    }
    color: $white;
    background-color: $warning !important;
  }

  @extend .bg-white, .py-2, .px-3, .default-radius, .box-shadow, .d-flex, .align-items-center;
}

.MuiBox-root {
  .css-pazuet {
    min-width: fit-content;
  }

  .MuiTypography-root {
    font-family: 'Rubik', sans-serif;

  }
}

.MuiTabs-flexContainer {
  .MuiButtonBase-root {
    font-family: 'Rubik', sans-serif;
    text-transform: capitalize;
    font-weight: $font-weight-medium;

    &.Mui-selected {
      font-weight: $font-weight-semi-bold;
    }
  }
}

@media (max-width: 768px) {
  #offer-modal {
    .MuiBox-root {
      padding: 0 !important;
    }
  }
}
