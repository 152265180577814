.measure-dropdown{
  width: 25%;
}
@media (max-width: 767px) {
  .measure-dropdown{
    width: 100%;
  }
}
.requested {
  //position: absolute;
  //right: -3.5rem;
  font-size: 9px;
  //top: 2px;
  color: $danger;
}