.offer-card-item {
  &.border-negotiate {
    border-right: 3px solid $info;
  }
  .offer-status-left {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: fit-content;
  }
  .image-top-right {
    position: absolute;
    top: 20px;
    right: 20px;
    width: fit-content;
  }
}
