#dropdown-basic {
  padding: 0.5rem;

  &:after {
    display: none;
  }
}

.dropdown-menu {
  border: none;
  border-radius: 8px;
  font-size: 14px;

  .dropdown-item {
    padding: 0.5rem 0.75rem;

    &:hover {
      background: transparent;
      color: $primary;
    }
  }

  &.show {
    @extend .shadow;
  }
}

.dropdown-secondary {
  .dropdown-toggle.btn {
    @extend .btn-outline-secondary;
    width: 100%;
    text-align: start;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
  }
}

.location-dropdown {
  width: 100%;
  text-transform: capitalize;

  .dropdown-toggle {
    min-height: 37px;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
  }

  .dropdown-menu {
    min-width: 30rem;
    padding: 2rem 1.5rem;
    @extend .box-shadow;
  }

  :after {
    border-top: 0.45em solid;
    border-right: 0.45em solid transparent;
    border-left: 0.45em solid transparent;
  }

}

@media(max-width: 1200px) {
  .location-dropdown {
    .dropdown-menu {
      min-width: 15rem !important;
    }
  }
}
