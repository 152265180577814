.card {
  @extend .box-shadow;
  border: 1px solid $light-gray;
  border-radius: $default-radius;
  width: 100%;
}


.campaign-user-list {
  position: relative;

  .campaign-card-img {
    position: absolute;
    top: -1.1rem;
    left: 0rem;
    z-index: 1;
    cursor: pointer;
  }
}

.reporting-agency-sticky-header {
  border-top-left-radius: $default-radius;
  border-top-right-radius: $default-radius;
  padding: 1rem;
  border: 1px solid $light-gray;
  position: sticky;
  background-color: $primary-light;
  z-index: 1;
  top: 70px;
}

.image-preview-card-height {
  height: 80px;
}

.campaign-dash-card{
  @extend .box-shadow;
  .campaign-img {
    @extend .img-cover, .default-radius;
    height: 105px;
    width: 105px;
  }
}

.brand-card {
  @extend .box-shadow;
  display: flex;
  align-items: stretch;
  margin-bottom: 30px;
  border: none;
  justify-content: space-between;

  .card-title {
    line-height: 1.2;
    font-weight: $font-weight-semi-bold;
    cursor: pointer;
  }

  .campaign-card-icons {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 1;
    display: flex;
  }

  @media only screen and (max-width: 1320px) and (min-width: 1200px) {
    .card-body {
      padding: 14px 14px
    }
  }

  .resize-cards {
    @media only screen and (min-width: 1200px) {
      .card-body {
        padding: 13px 13px
      }
    }
  }

}

.agency-img-overlay:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(0deg, white 0%, rgba(0, 0, 0, 0) 100%);
  height: 27%;
}

.account-card {
  cursor: pointer;
  padding: 1.5rem 1.5rem;
  width: 100%;

  img:first-child {
    max-height: 70px;
  }
}

.card-footer {
  background-color: $white;
  border-top: $card-border-width solid $card-border-color;
}

.campaign-multiselect {
  border: 1px solid transparent;
  cursor: pointer;
  @extend .text-muted;

  &.activeText, &.active, &:hover {
    @extend .text-dark;
  }


  img, h6 {
    opacity: 0.5;
  }

  &.active, &:hover, &.contract-1 {
    img, h6 {
      opacity: 1;
    }

    @extend .text-dark;

  }

  &.active {
    @extend .border-primary, .box-shadow-primary;
  }
}


.box-shadow-primary {
  box-shadow: 2px 2px 15px #05BBC95C;
}

.box-shadow-warning {
  box-shadow: 2px 2px 15px #f3dbad;
}

.content-files-card {
  @extend .border,
  .default-radius,
  .py-md-3;

  .card-img {
    aspect-ratio: 1/1;
    object-fit: cover;
  }
}

.reschedule-card {
  @extend .default-radius,
  .p-3,
  .cursor-pointer;
  border-color: $info;
  background-color: transparent;
}


@media (max-width: 768px) {
  .reporting-agency-sticky-header {

    top: 57px;
  }
}