@mixin max-height-vh() {
  $height-list: 10, 20, 30, 40, 50, 60, 65, 70, 80, 90, 100;

  @each $value in $height-list {
    .max-height-#{$value}vh {
      max-height: #{$value}vh;
    }
  }
}

@include max-height-vh();

.h-inherit {
  height: inherit;
}

.max-height-100 {
  max-height: 100%;
}

.background-gray-100 {
  background-color: $gray-100;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.bg-propose-light {
  background-color: $proposed-lighten;
}

.min-width-150px {
  min-width: 150px;
}

.w-90 {
  width: 90%;
}

.lh-1-5 {
  line-height: 1.5;
}

.w-40px {
  width: 40px;
}

.modal-dark {
  background: #000000a1;
}

.white-space-break-spaces {
  white-space: break-spaces;
}

.w-80px {
  width: 80px;
}

.bottom-10 {
  bottom: 10%;
}

.bottom-0 {
  bottom: 0;
}

.h-80px {
  height: 80px;
}

.left-0 {
  left: 0px;
}

.react-tel-input .form-control {
  padding: 15px 14px 15px 58px !important;
  height: 37px !important;
  font-size: 13px !important;
}

.cky-revisit-bottom-left {
  left: 10px !important;
  bottom: 135px !important;
  background-color: $primary !important;

  .cky-btn-preferences {
    background-color: $primary !important;
  }

  .cky-switch input[type="checkbox"]:checked {
    background-color: $primary !important;
  }
}

.euro-symbol {
  &::before {
    content: "€ ";
    font-weight: 400;
  }
}

.euro-and-plus-symbol {
  &::before {
    content: "+ € ";
    font-weight: 400;
  }
}

.input-icon {
  width: 100%;
  margin-bottom: 10px;

  .upload-image-icon {
    @extend .d-flex, .align-items-center, .justify-content-center, .bg-muted, .position-absolute, .cursor-pointer;
    bottom: 20px;
    right: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .icon {
    padding: 10px;
    color: green;
    min-width: 50px;
    text-align: center;
  }

  svg {
    position: absolute;
  }

  .input-field {
    width: 100%;
    cursor: pointer;
    opacity: 0;
    padding: 10px;
    text-align: center;
  }
}

.b-radius-left-side {
  border-radius: 5px 0 0 5px !important;
}

.MuiFormControlLabel-label {
  font-family: "Rubik", sans-serif !important;
  font-size: 14px !important;
  letter-spacing: 0px !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-1 {
  z-index: 1;
}

.default-radius {
  border-radius: $default-radius !important;
}

.default-top-radius {
  border-top-left-radius: $default-radius;
  border-top-right-radius: $default-radius;
}

.default-radius-top {
  border-top-right-radius: $default-radius;
  border-top-left-radius: $default-radius;
}

.default-radius-bottom {
  border-bottom-right-radius: $default-radius;
  border-bottom-left-radius: $default-radius;
}

.default-radius-sm {
  border-radius: 4px !important;
}

.max-h-180px {
  max-height: 180px;
}

.max-h-65vh {
  max-height: 65vh;
}

.max-width-100 {
  max-width: 100%;
}

.opacity-25 {
  opacity: 0.25;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

#cursor-initial {
  cursor: initial;
}

.opacity-50 {
  opacity: .50;
}


.opacity-75 {
  opacity: .75;
}

.opacity-100 {
  opacity: 1;
}

a {
  @extend .text-decoration-none;

  &:hover {
    color: $primary !important;
  }
}

.radius-50 {
  border-radius: 50%;
}

.w-inherit {
  width: inherit;
}

.w-70 {
  width: 70%;
}

.w-30 {
  width: 30% !important;
}

.w-65 {
  width: 65% !important;
}

.row {
  @extend .mx-0, .w-100;
}

.border-radius-8px {
  border-radius: 8px;
}

.word-break {
  word-break: break-all;
}

.word-break-word {
  word-break: break-word;
}

.rotate--120 {
  transform: rotate(-120deg);
}

.icon-round {
  padding: 6px;
  background-color: $black;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  aspect-ratio: 1/1;
  max-width: 30px;

  svg {
    width: 20px;
    height: 20px;
  }

  img {
    width: 20px;
    height: 20px;
  }
}

.social-icon {
  width: 33px;
  height: 33px;
}

.calendar-icon {
  padding: 3px;
  width: 23px;

  svg,
  img {
    width: 15px;
    height: 15px;
  }
}


.w-fit-content {
  width: fit-content !important;
}

.img-cover {
  object-fit: cover;
}

.img-object-fit-none {
  object-fit: none;
}

.cursor-pointer {
  cursor: pointer;
}

.campaign-card-icons {
  position: absolute;

  :not(:first-child) {
    right: -1rem;
    position: absolute;
  }
}

.textHover {
  color: $light-text;

  &:hover {
    color: $primary;

    svg {
      fill: $primary;
    }
  }
}

.textHoverStrong {
  color: $dark-text;

  &:hover {
    color: $primary;

    svg {
      fill: $primary;
    }
  }
}

.box-shadow {
  //box-shadow: 0 11px 42px -29px rgba(0, 0, 0, 0.39);
  //-webkit-box-shadow: 0 11px 42px -29px rgba(0, 0, 0, 0.39);
  //-moz-box-shadow: 0 11px 42px -29px rgba(0, 0, 0, 0.39);
  box-shadow: 1px 1px 14px 4px $light-gray;
}

.shadow-top {
  box-shadow: 0px -6px 6px -6px $light-gray;
}

.register-stepper-icon {
  @media (max-width: 768px) {
    width: 30%;
  }
  width: 15%;
}

.box-shadow-angle {
  box-shadow: rgb(0 0 0 / 10%) 2px 2px 2px -20px,
  rgb(0 0 0 / 5%) 2px 2px 2px 0px;
}

.warning-color {
  color: $warning;
}

.secondary-color {
  color: $secondary;
}

.info-color {
  color: $info;
}

.bg-info-lighter {
  background-color: $info-lighter;
}

.bg-primary-lighter {
  background-color: $primary-lighter !important;
}

.bg-danger-lighter {
  background-color: $danger-lighten;
}

.bg-warning-lighter {
  background-color: $warning-lighten;
}

.bg-success-lighten {
  background-color: $success-lighten;
}

.rotate-30deg {
  transform: rotate(-30deg);
}

.color-black {
  color: $black;
}

.close {
  margin-top: -10px !important;

  & span {
    font-size: 1.5rem;
  }
}

.width-3rem {
  width: 4rem;
}

.width-5 {
  width: 5.6rem;
}

.w-maxContent {
  width: max-content;
}

.rotate-180 {
  transform: rotate(180deg);
}

.w-md-50 {
  width: 100%;
}

button:disabled {
  cursor: not-allowed;
}


@media (max-width: 1200px) {
  .w-md-max-content {
    width: max-content;
  }
}

@media (min-width: 800px) {
  .w-lg-75 {
    width: 85% !important;
  }
}

@media (min-width: 569px) {
  .w-md-50 {
    width: 50%;
  }

}

@media (min-width: 769px) {
  .w-md-auto {
    width: auto !important;
  }
}

.country-flag {
  width: 30px;
  height: 30px;

  &.rounded {
    img {
      width: 100%;
      height: 100%;
      border-radius: 1rem;
      object-fit: cover;
      cursor: pointer;
    }
  }
}

.bg-muted {
  background-color: $gray-100 !important;
}

.h-60vh {
  height: 60vh;
}

.height-60vh {
  max-height: 60vh;
  overflow: auto;
}

.min-h-100 {
  min-height: 100%;
}

.min-h-40px {
  min-height: 40px;
}

.w-max-content {
  width: max-content;
}

.popover-body {
  max-height: 15rem;
  overflow: auto;
}

.w-md-75 { // we have media query down for width of for big screens 75%
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .influencer-side:not(.fc.fc-media-screen) {
    [class*='col-'] {
      padding: 0 !important;
    }
  }
}

#padding-right-mobile {
  @media only screen and (max-width: 768px) {
    padding-right: 5px !important;
  }
}

#padding-left-mobile {
  @media only screen and (max-width: 768px) {
    padding-left: 5px !important;
  }
}

@media only screen and (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-70 {
    width: 70% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-fit {
    width: fit-content !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
}

.height-65vh {
  height: 65vh;
}

.height-55vh {
  height: 55vh;
}

body:has(.loading-screen) {
  overflow: hidden;
}

.loading-screen {
  @extend .position-fixed, .d-flex, .align-items-center, .justify-content-center;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  width: 100%;
  height: 100vh;
  z-index: 9999;
}

.font-12 {
  .MuiOutlinedInput-root {
    @extend .font-12;
  }
}

.gy-1.row {
  div[class*="col"] {
    margin-top: 1rem;
  }
}

.aspect-ratio-1 {
  aspect-ratio: 1;
}

.aspect-ratio-sm-16-9 {
  aspect-ratio: 1;
}

.mr-first-child > div:first-child {
  margin-right: 1rem;

}

.place-top-right {
  position: absolute;
  top: 5%;
  right: 5%;
}


.place-top-left {
  position: absolute;
  top: 5%;
  left: 5%;
}

.place-bottom-right {
  position: absolute;
  bottom: 4%;
  right: 4%;
}

@media only screen and (max-width: 768px) {
  .aspect-ratio-sm-16-9 {
    aspect-ratio: 16/9;
  }

  .custom-row.row .pl-md-0.col-md-2.custom-col-sm-6 { // don't ask why, this is only way
    width: 50%;
    padding: 0.5rem !important;
  }

  .border-pending-color-mobile {
    border-color: $warning !important;
  }
}

.h-150px {
  height: 150px;
}

.height-386 {
  min-height: 386.66px;
}

.remove-html-styling {
  span, p {
    font-family: "Rubik", sans-serif !important;
    font-size: 12px !important;
    margin: 0px;
  }
}

.d-md-table-row_group {
  @media only screen and (min-width: 768px) {
    display: table-row-group !important;
  }
}

.grecaptcha-badge {
  top: 90px;
}

.quill {
  .ql-toolbar {
    border-top-right-radius: $default-radius;
    border-top-left-radius: $default-radius;
  }

  .ql-container {
    border-bottom-right-radius: $default-radius;
    border-bottom-left-radius: $default-radius;
  }
}

.diagonal-text {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  transform-origin: center;
  z-index: 1;
}

.diagonal-text::before {
  content: "";
  display: block;
  height: 0;
  padding-top: 100%;
}

.diagonal-text > span {
  display: inline-block;
  background: $primary;
  padding: 2px 45px;
  transform: translateY(-50%);
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  color: white;
  white-space: nowrap;

  &.diagonal-text-sm {
    padding: 2px 21px;
    font-size: 11px;
  }
}

.diagonal-text-bottom-left {
  position: absolute;
  bottom: 0; // Position the element at the bottom
  left: 0; // Align to the left
  display: inline-block;
  transform: translateX(0%) translateY(8%) rotate(50deg);
  transform-origin: center;
  z-index: 1;
}

.diagonal-text-bottom-left > span {
  padding: 2px 50px;
  display: inline-block;
  background: $primary;
  transform: translateY(-64%);
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  color: white;
  white-space: nowrap;
}

.diagonal-text-bottom-left::before {
  content: "";
  display: block;
  height: 0;
  padding-top: 100%;
}


.min-width-max-content {
  min-width: max-content;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.f-carousel__nav .f-button:disabled, .fancybox__nav .f-button:disabled {
  pointer-events: unset !important;
  display: none;
}
