#price-input {
  font-size: 0.875rem;
  width: 120px;

  span {
    width: 28px;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    background-color: white;
    padding: 0.4rem 0.5rem;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }

  input {
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
    height: 36px;
    width: 92px;
    font-size: 0.875rem;
  }

  @media only screen and (max-width: 768px) {
    width: 100px;

    input {
      width: 72px;
    }
  }

  &:focus {
    border-color: #d1d3e0;
    box-shadow: none;
  }
}


@media only screen and (max-width: 500px) {
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    select:focus,
    textarea:focus,
    input:focus {
      font-size: 16px !important;
    }
  }
}


.react-tel-input {
  .form-control {
    @extend .MuiOutlinedInput-root;
    color: $dark;

    &:focus {
      border-color: $primary !important;
      box-shadow: 0 0 0 1px $primary !important;
    }
  }

  .special-label {
    left: 10px !important;
    font-size: 0.6em !important;
    @extend .text-muted;

    &:after {
      content: " *"
    }
  }
}

#basic-textarea {
  @extend .MuiOutlinedInput-root;
  color: $dark;

  &:focus {
    border-color: #d1d3e0;
    box-shadow: none;
  }
}
