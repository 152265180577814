.side-overlay {
  width: 0;
  background-color: white;
  position: fixed;
  bottom: 0;
  height: calc(100vh - 65px);
  overflow-y: auto;
  z-index: 4;
  box-shadow: 0 30px 30px 0 $box-shadow-color;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  &.opened {
    width: 380px;
    opacity: 1;
    transition: width $default-transition ease, opacity 0.33s cubic-bezier(1, 0.18, 0, 1.53);
  }

  &.closed {
    width: 0;
    opacity: 1;
    transition: width $default-transition ease, opacity 0.2s cubic-bezier(0, 1.68, 1, 0.66);
  }

  hr {
    color: black;
    border-color: black;
  }
}

@media (max-width: 767px) {
  .side-overlay {
    &.opened {
      height: calc(100vh - 57px);
      width: 100%;
      transition: all $default-transition ease-in-out;
    }
  }
}

