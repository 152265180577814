
.icon-round {
  padding: 6px;
  //background-color: $black;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  aspect-ratio: 1/1;
  max-width: 33px;

  svg, img {
    width: 20px;
    height: 20px;
  }
}

.mobile-support-icon {
  position: absolute;
  opacity: 0;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
}

.shooting-star-icon {
  width: 50%;
  transform: rotate(180deg);
  mix-blend-mode: multiply; // remove white bg from image
}

.dash-package-close-icon {
  position: absolute;
  top: -5px;
  right: -10px;
  cursor: pointer;
}

.icon-dark {
  fill: $dark !important;
}

.proposed-icon {
  font-size: 1rem;
  color: $proposed;
}

.calendar-icon {
  @extend .icon-round;
  padding: 3px;
  width: 23px;
}

.scale-size-hover {
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.2);
  }
}

.campaign-icon {
  object-fit: cover;
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

.upload-icon {
  @extend .box-shadow, .d-flex, .align-items-center, .justify-content-center;
  width: 33px;
  height: 33px;
  background: $white;
  padding: 5px;
  border-radius: 50%;
  border: 2px solid rgba(128, 128, 128, 0.2);
}

.copy-icon {
  @extend .cursor-pointer;
  font-size: 22px;

  &:hover {
    fill: $primary;
  }
}