.progress-sm {
  height: 7px;
  border-radius: 31px;
  &.bg-secondary {
    background-color: $gray-200 !important;
  }
}

.progress-md {
  height: 10px;
  border-radius: 31px;
  background-color: $primary-lighter;

  &.warning {
    background-color: $warning-lighten;
  }

  &.info {
    background-color: $info-lighter;
  }
}


.progress-lg {
  height: 14px;
  border-radius: 31px;
  background-color: $primary-lighter;
}

.progress-label {
  height: 8px;
  width: 15px;
  border-radius: 30%;
}

.spinner {
  width: 120px !important;
  height: 120px !important;
  color: $primary !important;
}
