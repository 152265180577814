.carousel-indicators {
  z-index: 1;
}

.carousel-indicators li {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}


#campaign-item {
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    aspect-ratio: 2/1;
    object-fit: cover;
    border-top-left-radius: $default-radius;
    border-top-right-radius: $default-radius;
  }
}

#modal-carousel {
  img {
    @extend .default-radius;
    width: 100%;
    height: 24rem;
    object-fit: cover;
  }
}

.fancybox__button--slideshow {
  @extend .d-none;
}