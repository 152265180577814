.left-side-bar {
  display: flex;
  position: sticky;
  top: 4rem;
  width: 4rem;
  box-shadow: 0 20px 20px 20px $box-shadow-color;
  background-color: #f3f2f2;
  z-index: 1;
  bottom: 0;

  .campaign-list {
    @extend .d-flex, .flex-column, .align-items-center;
    max-height: 370px;
    overflow-y: auto;
    position: sticky;
    top: 5rem;

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
      visibility: hidden;
    }

    &:hover {
      &::-webkit-scrollbar-thumb,
      &::-webkit-scrollbar-track {
        visibility: visible;
      }
    }
    &.agency-side{
      max-height: 430px;
    }
  }

  .switch-btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #f3f2f2;
    position: absolute;
    right: 0;
    bottom: 7rem;
    margin-right: -20px;
    box-shadow: 0 2px $box-shadow-color;
    border: 0;
    width: 20px;
    height: 34px;
    padding: 0;
    z-index: 5;
  }

  .sidebar-image {
    @extend .mb-4;
    filter: grayscale(100%);
    cursor: pointer;

    &:hover {
      filter: grayscale(0);
    }

    &.active {
      filter: grayscale(0%);
    }
  }

  .wrapper {
    @extend .support-icon;
    transform: translate(0, -50%);
    position: fixed;
  }

  .tutorial-icon {
    margin-bottom: 100px;
  }

  .icon {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: $default-radius;
    filter: grayscale(85%);
    z-index: 1;

    &.active {
      filter: grayscale(0);
    }
  }

  &:hover {
    .icon {
      filter: grayscale(0%);
    }
  }

  &.opened {
    width: 4rem;
    transition: width $default-transition ease,
    opacity 0.33s cubic-bezier(1, 0.18, 0, 1.53);

    .option-bar {
      position: relative;
      left: 0;
    }
  }

  &.closed {
    width: 0;
    transition: width $default-transition ease,
    opacity 0.2s cubic-bezier(0, 1.68, 1, 0.66);

    .option-bar {
      position: relative;
      left: -2rem;
    }
  }
}

.support-icon {
  @extend .d-flex, .align-items-center, .justify-content-center;

  width: 2.575rem;
  height: 2.575rem;
  background: $white;
  border-radius: $default-border-radius;
  cursor: pointer;
  text-align: center;
  filter: grayscale(100%);
  bottom: 10px;

  &:hover {
    filter: grayscale(0);
  }

  &.tutorial {
    bottom: 65px;
  }

  //svg {
  //  font-size: 1.85rem;
  //  background: $gray-200;
  //  border-radius: $default-border-radius;
  //  padding: 0.3rem;
  //  z-index: 2;
  //}

  .slider {
    @extend .d-flex,
    .align-items-center,
    .justify-content-center;
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 2.4rem;
    width: 0;
    transform: translateX(-1rem);
    transition: all 1s ease;
    height: 2.575rem;
    background: $white;
    border-top-right-radius: $default-border-radius;
    border-bottom-right-radius: $default-border-radius;
    text-transform: uppercase;

    p {
      font-size: 13px;
      opacity: 0;
      margin-bottom: 0;
      padding-right: 0.5rem;
    }
  }

  &:hover {
    .slider {
      z-index: 1;
      width: max-content;
      transition: all 0.5s ease;
      transform: translateX(0);

      p {
        opacity: 1;
        transition: all 0.5s ease;
      }
    }
  }
}

.height-85vh {
  height: 85vh;
}

.left-side-bar.height-90vh {
  transition: height 0.1s ease-in;
  height: 90vh;
}

.left-side-bar.height-95vh {
  height: 95vh;
  transition: height 0.1s ease-in;
}

.height-77vh {
  height: 77vh;
}

.height-80vh {
  height: 80vh;
}

.height-69vh {
  height: 69vh;
}

.height-60vh {
  height: 60vh;
}

.height-63vh {
  height: 63vh;
}

#management_modal {
  width: 100%;
}

.influencer-mobile {
  @media (max-width: 991px) {
    display: none;
  }
}

@media (max-width: 769px) {
  .left-side-bar {
    display: none;
  }
}

@media (max-width: 991px) {
  .left-side-bar {
    top: 8rem;
  }
  .height-85vh {
    height: 77vh;
  }
}
