.profile-card {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  .messages {
    font-size: 0.8rem;
    color: $gray-500;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  .icons-bg {
    width: 30px;
    height: 30px;
    background-color: $gray-200;
    border-radius: $default-border-radius;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    .icon {
      color: $gray-500;
      width: 20px;
      height: 20px;
    }
  }

  .profile-img {
    width: 6.25rem;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: $default-border-radius;
  }

  .col {
    padding-right: 6px;
    padding-left: 6px;
  }
  .card-profile:nth-child( odd ) {
    padding-left: 0px;
    padding-right: 8px;
  }

  .card-profile:nth-child( even ) {
    padding-left: 8px;
    padding-right: 0px;
  }

  .card {
    @extend .justify-content-center, .justify-content-md-evenly;
    height: 5rem;
    flex-direction: row;
    align-items: center;
    border-style: dashed;
    cursor: pointer;
    padding: 0.5rem !important;

    .absolute-img {
      position: absolute;
      top: -8px;
      right: -8px;
    }

    img {
      width: 2.2rem;
      aspect-ratio: 1/0.85;
      object-fit: cover;
    }

    p {
      font-weight: $font-weight-semi-bold;
      font-size: 14px;
      margin-bottom: 0;
      padding: 0 0.5rem;

      span {
        color: $light-text;
        text-transform: uppercase;
        margin-bottom: 0;
        font-size: 12px;
        font-weight: $font-weight-normal;
      }

      .is-completed {
        position: absolute;
        width: 30px;
        height: 30px;
        object-fit: contain;
        right: 0;
        top: 0;
      }
    }

    .last-row {
      align-items: flex-end;
      height: 100%;

      .notification {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
          margin-bottom: 0;
          font-size: 12px;
        }

        p:nth-child(2) {
          background-color: #FFE2E5;
          color: $danger;
          text-align: center;
          border-radius: 15px;
        }

        img {
          height: 40px;
          width: 40px;
        }
      }

      .btn {
        width: 100%;
      }
    }
  }
}

.user-card {
  img {
    width: 74px;
    height: 74px;
    border-radius: 50%;
    object-fit: cover;
  }
}
