header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem;
  background-color: $white;
  box-shadow: 0 4px 5px -2px $box-shadow-color;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 3;

  &.mobile {
    padding: 1rem;
    box-shadow: none;
    border-bottom: 1px solid $gray-400;
    height: 57px;
    justify-content: space-between;
  }

  .d-inline-flex {
    text-align: right;

    p {
      margin-right: 30px;
      font-size: 16px;
      font-weight: $font-weight-semi-bold;
      margin-bottom: 0;
    }

    .btn {
      margin-right: 30px;
      font-size: 12px;
      padding: 0.2rem 0.3rem;
      text-transform: none;
      background-color: transparent;
      color: $primary;
    }
  }

  .profile-img {

    img {
      width: 3.125rem;
      aspect-ratio: 1/1;
      object-fit: cover;
      cursor: pointer;
      border-radius: $default-border-radius;
    }
  }

  .mobile-links {
    padding: 30px 20px;
    position: absolute;
    top: 57px;
    left: 0;
    width: 100%;
    height: calc(100% - 57px);
    background-color: white;
    z-index: 2;

    .header-link-item {
      margin-bottom: 40px;
      color: $gray-600;
    }

    &.opened {
      transform: translate(0, 0);
      transition: transform 0.33s ease-in-out;

    }

    &.closed {
      transform: translate(-100%, 0);
      transition: transform 0.33s ease-in-out;
    }

    .question {
      cursor: pointer;
      color: #2D65B9;
      width: 30px;
      height: 30px;
    }

    .footer {
      border-top: 1px solid $gray-400;
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 80px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;

      .icons-bg {
        width: 50px;
        height: 50px;

        .notifications-number {
          position: absolute;
          background-color: $secondary-lighter;
          border-radius: 40%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 16px;
          height: 16px;
          font-size: 10px;
          color: $secondary;
          top: 6px;
          right: 6px;
        }

        * {
          width: 30px;
          height: 30px;

          &.bell {
            color: $secondary;
          }

          &.share {
            color: #76B7EF;
          }
        }
      }
    }
  }

  .header-link-item {
    display: flex;
    align-items: center;
    margin-right: 2.5rem;
    cursor: pointer;

    &:hover {
      color: $primary;
    }
  }
}

.sidebar-bullet {
  background-color: $danger;
  border-radius: 50%;
  min-width: 15px;
  height: 15px;
  padding: 2px;
  color: $white;
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -5px;
  top: -5px;
}

@media (max-width: 769px) {
  header {
    display: none;

    &.mobile {
      display: flex;
    }
  }
}

@media (min-width: 769px) {
  header {
    &.mobile {
      display: none;
    }
  }
}
