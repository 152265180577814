.inbox-message {
  @extend .text-muted, .white-space;
  max-width: 250px;
}

.chat-header {
  height: 50px;
  border: 1px solid $light-gray;
}

.new-message-badge {
  position: absolute;
  top: -5px;
  right: 15%;
  z-index: 1;
}

.message-tagged-user {
  background-color: $primary;
  color: $white;

  &.my-msg {
    background-color: $warning;
  }

  margin-bottom: 3px !important;
  width: fit-content;
  padding: 1px 2px 1px 2px;
  font-size: 11px;
  border-radius: 8px;
}

.global-chat-bubble {
  .wrapper {
    width: 100%;
    justify-content: end;
    display: grid;
    grid-template-rows: 50px;
    column-gap: 12px;
  }
}

.chat-accordion {
  bottom: 0;
  height: fit-content;
}

.mention-input {
  .mention-input__input {

    &::placeholder {
      font-size: 11px;
    }
  }

  .mention-input__suggestions {
    top: -100px !important;
    margin-top: 0 !important;
    background-color: transparent !important;
  }

  &.one {
    .mention-input__suggestions {
      top: -50px !important;
    }
  }
}

.user-img-rounded {
  &.chat-input {
    width: 30px;
    height: 30px;
  }
}

.notSeenBubble {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 35%;
  right: 0;
  border-radius: 50%;
  background-color: $primary;
}

.user-chat {
  position: relative;
  @extend .d-flex, .align-items-center, .my-1, .cursor-pointer, .pl-3, .pr-2, .py-2;

  &.true {
    background-color: #eeeeee;
  }

  &:hover {
    background-color: #eeeeee;
  }
}

.influencer-user-chat {
  @extend .d-flex, .align-items-center, .p-2, .cursor-pointer, .mb-1;

  &.true {
    background-color: #eeeeee;
  }

  &:hover {
    background-color: #eeeeee;
  }
}

.chat-status-info {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: absolute;
  bottom: 0;
  left: 50%;
  background-color: #13e921;
}

.chat-sent-message {
  width: fit-content;
  border-radius: $default-radius;
  padding: 1rem;
  color: $black;
  max-width: 90%;

  &.false {
    background-color: #f3f6f9;
  }

  &.true {
    background-color: #d9f4f6;
  }
}

.chat-input-field {
  border: none;
  width: 100%;
  padding: 0.5rem 0.3rem;

  &:focus-visible {
    outline: none;
  }
}

.chat-input-icon {
  color: #9ea5ab;
  cursor: pointer;
  font-size: 24px;

  &.send, &:hover, &.haveFile {
    color: $primary;
  }

}

// .chat-input-height {
//   height: 100px;
// }

@media only screen and (max-width: 768px) {
  .inbox-message {
    max-width: 200px;
  }
  .chat-sent-message {
    max-width: 100%;
  }
  // .chat-input-height {
  //   height: 72px;
  // }
}

