.badge {
  color: $white;
  position: absolute;
  font-size: 10px;
  border-radius: 0.50rem;
  text-align: center;
  padding: 3px 5px;
}

.offer-by-agency-badge {
  width: 80%;
  margin: auto;
  @extend .p-1;
  background-color: rgb(255, 255, 255, 0.8);
  border-radius: $default-radius;
}

.top-right-bullet {
  @extend .new-message-badge;
  top: -15%;
}

.offer-by-agency-wrapper {
  @extend .position-absolute, .z-index-1, .text-center, .px-0, .bottom-10;
}

@media (max-width: 768px) {
  .offer-by-agency-wrapper {
    bottom: 42%;
  }
}

.interests-badge {
  @extend .text-muted;
  background-color: $light;
  border-radius: $default-radius;
  padding: 0.25rem 0.75rem;
  font-size: 12px;
}

.light-badge {
  border: 1px solid $default-border-color;
  @extend .mx-1, .p-1, .default-radius;
}

.notification-comment-badge {
  @extend .position-absolute, .bg-danger, .text-white, .d-flex, .align-items-center, .justify-content-center;
  font-size: 10px;
  border-radius: 50%;
  top: 1.2rem;
  right: 1.5rem;
  width: 13px;
  height: 13px;
}

.MuiButtonGroup-root.MuiButtonGroup-outlined {

  .MuiBadge-root {
    margin-right: 0;

    .MuiBadge-anchorOriginTopRightRectangular {
      background-color: transparent;
      color: $black;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.custom-badge {
  border-radius: 7px;
  width: fit-content;
  text-transform: uppercase;
  font-size: 10px;
  padding: 2px 5px;
  border: 1px solid;

  &.long-term {
    background-color: $primary-lighter;
    border-color: $primary;
  }

  &.additional {
    background-color: $warning-lighter;
    border-color: $warning;
  }
  &.on-top{
    background-color: $info-lighter;
    border-color: $info;
  }
}

