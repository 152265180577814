.booking-card {
  @extend .box-shadow;
  border: 1px solid #eeeeee;
  border-radius: 7px;
  width: 100%;

  .border-top-right {
    border-top-right-radius: $default-radius;
    border-top-left-radius: $default-radius;
  }

  .carousel,
  .carousel-inner,
  .carousel-item {
    height: 100%;
  }

  &.border-pending {
    border-right: 3px solid $warning;
  }

  &.border-requested {
    border-right: 3px solid $info;
  }

  &.border-negotiate {
    border-right: 3px solid $info;
  }

  &.border-rejected {
    border-right: 3px solid $secondary;
  }

  &.border-expired {
    border-right: 3px solid $secondary;
  }

  &.border-canceled {
    border-right: 3px solid $secondary;
  }

  &.border-declined {
    border-right: 3px solid $secondary;
  }

  &.border-selected {
    border-right: 3px solid $warning;
  }

  &.border-completed {
    border-right: 3px solid $success;
  }

  &.border-accepted {
    border-right: 3px solid $success;
  }

  p {
    margin-bottom: 0;
  }

  .line-height-16 {
    line-height: 16px;
  }
}

.booking-card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 0.4rem 0 0 0.4rem;
}

.booking-card-info {
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 1;
  padding: 0.5rem;
  top: 0;

  span, p {
    font-weight: 600;
    padding-left: 0.3rem;
    color: $white;
  }
}

.rating {
  @extend .py-1, .px-2;
  display: flex;
  align-items: center;
  width: fit-content;
  background: $light;
  border-radius: $default-radius;
  color: $black !important;
}

.five-line-text {
  @extend .text-muted;
  padding-top: 0.3rem;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: inherit;
  position: relative;
  margin-bottom: 0.25rem;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 25%;
    width: 100%;
    content: "";
    pointer-events: none; /* so the text is still selectable */
  }
}

.intro {
  padding-top: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: inherit;
  position: relative;
  margin-bottom: 0;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 25%;
    width: 100%;
    content: "";
    background: linear-gradient(
                    to top,
                    #fafafa 20%,
                    rgba(255, 255, 255, 0) 80%
    );
    pointer-events: none; /* so the text is still selectable */
  }
}

.booking-list-card {
  @extend .booking-card;
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;

  td {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top: none;
  }

  .booking-list-image {
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 0.4rem 0 0 0.4rem;
    position: relative;
    width: 7rem;
  }

  .list-intro {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    position: relative;
    margin-bottom: 0;
    text-overflow: ellipsis;
  }

  .image-sticker {
    @extend .rating;
    position: absolute;
    left: 0.5rem;
    bottom: 0.5rem;
    padding: 0.2rem;
    opacity: 0.9;
  }

  .line-height-16 {
    line-height: 16px;
  }
}

.rating-icon-bg {
  background-color: $primary-lighter;
  color: $primary;
  border-radius: 0.3rem;
  width: 22px;
  height: 22px;
  padding: 0.2rem;
  margin: 0 0.2rem;
}

#social-networks-accordion {
  &::before {
    background-color: transparent;
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }

  .MuiTabs-flexContainer {
    .MuiButtonBase-root.MuiTab-root {
      min-width: 55px;
      padding: 0.2rem;
    }
  }
}

@media (max-width: 768px) {
  .booking-card-image {
    border-radius: 0.4rem 0.4rem 0 0;
  }
}


#close {
  .close {
    padding-top: 0.5rem;
  }
}

.modal-header {
  border-bottom: 0 none;
}

.modal-footer {
  border-top: 0 none;
}

.top-1rem {
  top: 1rem;
}

.carousel-inner {
  border-radius: 0.4rem;

  .booking-card-image {
    border-radius: 0.4rem;
  }
}

.text-shadow {
  text-shadow: black 0px 0px 3px;
}

.booking-card {
  .MuiTabs-scroller {
    button {
      color: transparent !important;
    }
  }
}

.bestPick {
  height: 1rem;
  padding-top: 1px;
}

#offer-modal {
  .modal-body {
    height: 80vh;
  }

  .profile-info {
    height: 77vh;
    overflow: auto;
  }

  .modal-right-content {
    height: 65vh;
    overflow: auto;
  }

  @media (max-width: 1200px) {
    .modal-body {
      padding: 10px !important;
      padding-right: 5px !important;
    }
    .negotiate-status-mobile {
      border: 2px solid $info;
      border-bottom: 0px;
      border-top-right-radius: 0.3rem;
      border-top-left-radius: 0.3rem;
    }
    .modal-footer {
      box-shadow: 0 -7px 5px -5px $light-gray;
      padding: 5px 10px;
    }
    .profile-info {
      height: auto;
      overflow: visible;
    }
    .modal-right-content {
      overflow: auto;
      height: 60vh;
      padding-right: 5px !important;
      overflow-x: hidden;
    }
  }

  #simple-tabpanel-0 {
    .MuiBox-root {
      padding: 0.5rem 0;
    }
  }

  #simple-tabpanel-4 {
    .MuiBox-root {
      padding: 0 1rem;
    }
  }
}

.bookedPlatform-position {
  position: absolute;
  bottom: 20px;
  left: 10px;
}

.gradient-from-bottom {
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    pointer-events: none;
  }
}

.offer-status-badge {
  border-top-right-radius: $default-radius;
  border-top-left-radius: $default-radius;
  z-index: -1;
  position: absolute;
  right: -85px;
  rotate: 90deg;
  background: purple;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 60px;
  padding: .4rem 1rem;
  width: 140px;

  svg {
    rotate: 120deg;
  }

  p {
    margin-bottom: 0;
    padding-left: .3rem;
    text-transform: uppercase;
    color: $white;
  }

  &.pending {
    background-color: $warning;
  }

  &.requested {
    background-color: $info;
  }

  &.negotiate {
    background-color: $info;
  }

  &.rejected {
    background-color: $secondary;
  }

  &.expired {
    background-color: $secondary;
  }

  &.canceled, &.private {
    background-color: $secondary;
  }

  &.declined {
    background-color: $secondary;
  }

  &.selected {
    background-color: $primary;
  }

  &.completed, &.published {
    background-color: $success;
  }

  &.accepted {
    background-color: $success;
  }
}

.reporting-modal-height {
  min-height: 550px;
}


@media only screen and (max-width: 768px) {
  .reporting-modal-height {
    min-height: 250px;
  }
}

.delete-margin-bottom {
  span {
    margin-bottom: 0px !important;

  }
}

.external-search-card {
  .carousel-img {
    width: 120px;
    height: 120px;
  }

  .carousel-inner {
    @extend .carousel-img
  }

  .placeholder-background {
    width: 60px;
    height: 25px;
    background-color: $gray-100;
    border-radius: $default-border-radius;
  }
}

.influencer-wishlist-card {
  max-height: 180px;
  overflow: auto;
}

.comment-tab {
  @media only screen and (max-width: 1200px) {
    height: 48vh;
  }
  @media only screen and (min-height: 1050px) {
    height: 56vh;
  }
  @media only screen and (min-height: 1250px) {
    height: 58vh;
  }
  height: 53vh;

}
