.btn {
  border-radius: $default-border-radius;
  font-size: 12px;
  padding: 6px 12px;
  font-weight: $font-weight-medium;
  text-transform: uppercase;


  &:focus {
    box-shadow: none !important;
  }

  &.active {
    color: white !important;
  }
}

.wa-button {
  padding: .5rem;
  border: none;
  border-radius: 25px;
  color: $white;
  font-weight: 600;
  width: 100%;
  background: #18C554;
}

.dropdown-secondary.active .dropdown-toggle, .MuiChip-labelSmall {
  color: $gray-900 !important;
}

.tutorial-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background: $white;
  //border: 1px solid gray;
  border: none;
  border-radius: 50%;
  box-shadow: 1px 1px 14px 8px #eeeeee;
}


.btn-sm {
  border-radius: $default-border-radius;
  font-size: 12px;
  padding: 8px 16px;
  line-height: 1.15;

  &:focus {
    box-shadow: none !important;
  }
}

.btn-outline-primary {
  background-color: $white;
  border: 1px solid $primary;
  color: $primary;

  &:hover,
  &:focus {
    background-color: $primary;
    color: $white;
  }
}

.btn-success {
  @extend .btn-sm;
  color: $white;
  background-color: $success;

  &:hover {
    color: $white;
  }
}

.btn-warning {
  @extend .btn-sm;
  color: $white;
  background-color: $warning;

  &:hover {
    color: $white;
  }
}

.btn-danger {
  @extend .btn-sm;
  color: $white;
  background-color: $danger;

  &:hover {
    color: $white;
  }
}

.btn-icon {
  @extend .box-shadow-angle;
  background-color: $white;
  border-radius: $default-border-radius;
  padding: 0.55rem;

  svg {
    color: $secondary;
    font-size: 16px;
  }

  &.active {
    svg {
      color: $primary;
    }
  }
}

.btn-dots {
  width: 31px;
  height: 31px;
  background-color: $gray-100;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.4rem;
  border-radius: $default-radius;
  cursor: pointer;

  svg {
    color: $light-text;
  }
}

.btn-outline-secondary {
  border: 1px solid $gray-400;
  background-color: $white;
  color: $secondary;
  text-transform: none;
  font-weight: 400;

  svg {
    fill: $gray-500;
  }

  &:hover {
    background-color: $white;
    border-color: $gray-500;
  }

  &.active {
    svg {
      fill: $white;
    }
  }
}

.btn-outline-success {
  @extend .btn-outline-success;

  &:hover {
    color: $white;
  }
}

.btn-outline-muted {
  @extend .btn-outline-secondary;
  color: $black;

  &:hover {
    &.sent {
      border-color: $info;
      color: $info;
      background-color: lighten($info, 50%);
    }

    &.booked {
      border-color: $success;
      color: $success;
      background-color: lighten($success, 35%);
    }

    &.received {
      border-color: $warning;
      color: $warning;
      background-color: lighten($warning, 50%);
    }

    &.declined {
      border-color: $danger;
      color: $danger;
      background-color: lighten($danger, 35%);

    }
  }
}

.btn-outline-warning {
  @extend .btn-outline-secondary;

  &:hover {
    border: 1px solid $warning;
    color: $black;

    svg {
      fill: $warning;
    }
  }

  svg {
    fill: $warning;
  }

  &.active {
    svg {
      fill: $white;
    }
  }
}

.btn-outline-bestPick {
  @extend .btn-outline-secondary;
  max-height: 31.84px;

  svg {
    fill: $yellow;
  }

  &:hover {
    border: 1px solid $yellow;
    color: $black;
  }

  &.active {
    background-color: $yellow !important;
    border: 1px solid $yellow !important;

    svg {
      fill: $white;
    }
  }
}

.btn-outline-accepted {
  @extend .btn-outline-secondary;

  &:hover {
    border: 1px solid $success;
    color: $black;

    svg {
      fill: $success;
    }
  }

  svg {
    fill: $success;
  }

  &.active {
    background-color: $success !important;
    border: 1px solid $success !important;

    svg {
      fill: $white;
    }
  }
}

.store-btn-wrapper {
  position: absolute;
  top: 55%;
  left: 20px;
  flex-direction: column;
  width: 25%;

  .store-button {
    padding: 5px;
    width: 100%;
    border-radius: 7px;
    outline: none;
    border: none;
    background: #000000;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    @extend .text-white;

    &:hover {
      background: lighten(#000000, 10%);
    }

    .store-icon {
      width: 20px;
      height: 20px;
    }
  }
}

@media (max-width: 768px) {
  .store-btn-wrapper {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    top: unset;
    bottom: 0;
    left: unset;
    margin-bottom: 0;

    .store-button {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

.btn-clear-selection {
  border: 1px solid $warning;
  background-color: $white;
  text-transform: none;
  font-weight: 400;
  color: $warning;

  &:hover {
    border: 1px solid $warning;
    background-color: $warning;
    color: $white;
  }
}

.btn-outline-info {
  @extend .btn-outline-info;

  &:hover {
    border: 1px solid $info;
    //color: $black;

    svg {
      fill: $info;
    }
  }

  svg {
    fill: $info;
  }

  &.active {
    svg {
      fill: $white;
      color: white;
      background-color: $info;
    }
  }
}

.btn-outline-gray {
  @extend .btn-outline-secondary;

  &:hover {
    border: 1px solid $secondary;
    color: $black;

    svg {
      fill: $secondary;
    }
  }

  svg {
    fill: $secondary;
  }
}

.calendar-buttons {
  @extend .border;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 8px 12px;
  margin: 0 0.2rem;
  width: fit-content;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid transparent;

  &:hover {
    &.success-dot {
      border-color: $success !important;
    }

    &.primary-dot {
      border-color: $primary !important;
    }

    &.warning-dot {
      border-color: $warning !important;
    }

    &.danger-dot {
      border-color: $danger !important;
    }

    &.black-dot {
      border-color: $black !important;
    }
  }

  &.success-dot.active {
    border-color: $success !important;
  }

  &.primary-dot.active {
    border-color: $primary !important;
  }

  &.warning-dot.active {
    border-color: $warning !important;
  }

  &.danger-dot.active {
    border-color: $danger !important;
  }

  &.black-dot.active {
    border-color: $black !important;
  }
}

.inbox-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 10px #00000010;
  border-radius: $default-radius;
  cursor: pointer;

  i {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9ea5ab;
  }

  &:not(:first-of-type) {
    margin-left: 1.5rem;
  }

  &.active, &.unread {
    width: fit-content;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    @extend .text-muted;
  }

  &:hover,
  &.active {

    i {

      transition: 0.3s ease-in-out;
      @extend .text-primary;
    }
  }


}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd {
  margin-right: 0px;
}

.chat-show-button {
  @extend .border,
  .w-fit-content,
  .cursor-pointer,
  .position-absolute,
  .bg-white;
  transition: 0.5s ease-in;
  right: 0;
  z-index: 2;
  top: 2%;
  border-radius: $default-border-radius 0 0 $default-border-radius;

  svg {
    fill: $light-text;

    &:hover {
      fill: $primary;
    }
  }
}

.calendar-button-dot {
  &.active {
    border: 1px solid $primary;
  }

  &:before {
    content: "";
    height: 0.7rem;
    width: 0.7rem;
    border-radius: 50%;
    display: inline-block;
  }
}


$dots: (
        "proposed": $proposed,
        "warning": $warning,
        "feedback_loop": $feedback_loop,
        "success": $success,
        "danger": $danger,
        "info":$info,
        "primary": $primary,
        "black": #2e3033,
);

@each $name, $value in $dots {
  .#{$name}-dot:before {
    background-color: $value;
    margin-right: 0.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .calendar-buttons {
    width: 100%;
    justify-content: center;
  }
}

.MuiRadio-root.Mui-checked {
  color: $primary !important;
}
