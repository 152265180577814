.MuiStepIcon-root {
  color: #D3D7DB !important;
  width: 1.5em !important;
  height: 1.5em !important;

  &.Mui-completed, &.Mui-active {
    color: $primary !important;

    .MuiStepIcon-text {
      fill: $white !important;
    }
  }
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: $primary !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: $primary !important;
}

.MuiStepConnector-horizontal {
  &.Mui-completed, &.Mui-active {
    .MuiStepConnector-line {
      border-color: $primary !important;
    }
  }

  &.Mui-active + div + .Mui-disabled {
    .MuiStepConnector-line {
      border-color: #C3E9EC !important;
    }
  }

  //{
  //  .MuiStepConnector-line {
  //  }
  //}
}

.MuiStepLabel-horizontal .MuiStepLabel-iconContainer {
  padding-right: 0;
}

.MuiStep-horizontal {
  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.MuiStepIcon-text {
  fill: #9EA5AB !important;
  font-weight: $font-weight-semi-bold !important;
  //font-size: 16px!important;
}

.MuiStepIcon-active .MuiStepIcon-text {
  fill: white !important;
}

.MuiStepConnector-alternativeLabel {
  top: 16px !important;
  left: calc(-50% + 35px) !important;
  right: calc(50% + 35px) !important;
}

.MuiStepConnector-lineHorizontal {
  border-top-width: 3px !important;
}

.MuiStepConnector-line {
  border-color: #D3D7DB !important;
}

.MuiStep-completed .MuiStepConnector-line {
  border-color: $primary !important;
}

.MuiStepConnector-active .MuiStepConnector-line {
  border-color: $primary-lighter !important;
}