.status {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: $font-weight-regular;
  border-radius: 7px;
  border-left: 3px solid;
  padding: 4px 6px;
  margin-bottom: 0;
  background-color: $light;

  &.reported, &.due, &.completed {
    border-left-color: $success;
    background-color: $success-lighter;
  }

  &.inprogress,
  &.published,
  &.pending {
    border-left-color: $warning;
    background-color: $warning-lighter;
  }

  &.feedback_loop {
    border-left-color: $feedback_loop;
    background-color: $feedback_loop-lighten;
  }

  &.proposed {
    border-left-color: $proposed;
    background-color: $proposed-lighten;
  }

  &.feedback,
  &.not_due,
  {
    border-left-color: $danger;
    background-color: $danger-lighter;
  }

  &.accepted, &.approved {
    border-left-color: $primary;
    background-color: $primary-lighter;
    font-weight: $font-weight-normal;
  }

  &.started,
  &.ongoing {
    border-left-color: $primary;
    background-color: $primary-lighter;
    font-weight: $font-weight-normal;
  }

  &.negotiate,
  &.scheduled,
  &.draft,
  &.invite,
  &.paused {
    border-left-color: $info;
    background-color: $info-lighter;
    font-weight: $font-weight-normal;
  }

  &.finished, &.overdue {
    border-left-color: $danger;
    background-color: $danger-lighter;
    font-weight: $font-weight-normal;
  }
}

#status {
  margin-top: 9.5px;
}
