.dropzone {
  cursor: pointer;
  flex: 1;
  display: flex;
  overflow-x: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: $default-radius;
  background-image: repeating-linear-gradient(-17deg, $default-border-color, $default-border-color 6px, transparent 6px, transparent 13px, $default-border-color 13px), repeating-linear-gradient(73deg, $default-border-color, $default-border-color 6px, transparent 6px, transparent 13px, $default-border-color 13px), repeating-linear-gradient(163deg, $default-border-color, $default-border-color 6px, transparent 6px, transparent 13px, $default-border-color 13px), repeating-linear-gradient(253deg, $default-border-color, $default-border-color 6px, transparent 6px, transparent 13px, $default-border-color 13px);
  background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  color: $default-border-color;
  outline: none;
  transition: border 0.24s ease-in-out;
  min-height: 195px;

  &.error {
    background-image: repeating-linear-gradient(-17deg, $danger, $danger 6px, transparent 6px, transparent 13px, $danger 13px),
    repeating-linear-gradient(73deg, $danger, $danger 6px, transparent 6px, transparent 13px, $danger 13px),
    repeating-linear-gradient(163deg, $danger, $danger 6px, transparent 6px, transparent 13px, $danger 13px),
    repeating-linear-gradient(253deg, $danger, $danger 6px, transparent 6px, transparent 13px, $danger 13px);
  }
}

.thumbs-container {
  display: flex;
}

.thumb {
  @extend .default-radius, .mx-1;
  display: flex;
  border: 1px solid #eaeaea;
  height: 100%;
  padding: 4px;
  box-sizing: border-box;
  max-height: 198px;
  width: fit-content;

  .thumb-inner {
    display: flex;
    width: 100%;
    overflow: hidden;

    img {
      @extend .default-radius;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}

.min-140px {
  min-height: 140px;
}
