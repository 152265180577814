.MuiBox-root {
  div {
    margin-bottom: 0;
    //overflow: auto;
  }

  .MuiButtonGroup-root.MuiButtonGroup-outlined {
    .MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary {
      @extend .box-shadow;
      border: 1px solid $light-gray;
      border-radius: 50%;
      min-width: 20px;
      aspect-ratio: 1/1;
      object-fit: cover;
      font-size: 18px;
      padding: 0;
      background-color: $white;
      color: $secondary;
      margin: 0 1rem;

      &:not(.Mui-disabled) {
        &:hover {
          color: $primary;
        }
      }

      &.Mui-disabled {
        opacity: 0.5;
      }
    }
  }
}

.mui-scroll-tab {
  .MuiTabs-flexContainer {
    overflow: auto;
  }
}